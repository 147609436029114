import idx from "idx";
import { ColumnProps } from "antd/lib/table";
import {
  GetPaymentList,
  GetPaymentListVariables,
  GetPaymentList_payments_items as Payment,
} from "./types/GetPaymentList";
import GET_PAYMENT_LIST from "./getPaymentList.graphql";
import React, { FC, useCallback, useMemo } from "react";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import GraphQLTable, {
  useColumnSearch,
  creationTime,
  lastModificationTime,
} from "components/graphQLTable";

const Payments: FC = () => {
  const { createColumn: createOrderNumberColumn } = useColumnSearch<Payment>(
    "orderNumber"
  );

  const { createColumn: createStatusColumn } = useColumnSearch<Payment>(
    "status"
  );

  const columns = useMemo<Array<ColumnProps<Payment>>>(
    () => [
      { dataIndex: "id", title: "#", sorter: true },
      { dataIndex: "user.buyer.name", title: "Покупатель" },
      { dataIndex: "user.buyer.oldCode", title: "Код покупателя" },
      { dataIndex: "amount", title: "Сумма" },
      { dataIndex: "currency", title: "Валюта" },
      createOrderNumberColumn({
        title: "Номер заказа",
        sorter: true,
      }),
      createStatusColumn({ title: "Сатус", sorter: true }),
      creationTime,
      lastModificationTime,
    ],
    [createOrderNumberColumn, createStatusColumn]
  );

  const getDataSource = useCallback(
    (data?: GetPaymentList) => idx(data, x => x.payments),
    []
  );

  return (
    <Authorize>
      <MainLayout title="Платежи">
        <GraphQLTable<Payment, GetPaymentList, GetPaymentListVariables>
          columns={columns}
          query={GET_PAYMENT_LIST}
          getDataSource={getDataSource}
        />
      </MainLayout>
    </Authorize>
  );
};

export default Payments;
